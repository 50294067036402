import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Typography, Box} from '@mui/material';
import Stack from '@mui/material/Stack';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const BorderLinearProgressSuccess = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#28a745' : '#20c997',
    },
  }));
  
  
  function LinearProgressWithLabel(props) {
  let {value, validBets, turnOver} = props;
  console.log(value, 'prog')
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
        {value == 100 ? 
            <BorderLinearProgressSuccess variant="determinate"  {...props} />
            :
            <BorderLinearProgress variant="determinate"  {...props} />
        }
        </Box>
        <Box sx={{ minWidth: 35 }} className="mt-2">
        {value == 100 ? 
            <Typography variant="body2" className="text-yellow" >Completed</Typography>
            :
          <Typography variant="body2" className="text-yellow" >Valid Bets ₱{Number(validBets).toFixed(2)} / ₱{Number(turnOver).toFixed(2)} Turnover</Typography>
        }
        
        </Box>
      </Box>
    );
  }


export default function CustomizedProgressBars({progress, validBets, turnOver}) {
  return (
    <Box  sx={{ width: '100%', flexGrow: 1 }} className="mt-2">
    <Stack spacing={2} sx={{ width: '100%', flexGrow: 1 }}>
    <LinearProgressWithLabel value={progress} validBets={validBets} turnOver={turnOver} />
    </Stack>
    </Box>
  );
}
