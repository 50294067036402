import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Box, Chip, DialogTitle, DialogContent, IconButton, Typography, FormControl, TextField, Button, FormGroup, MenuItem, FormLabel } from '@mui/material';


//Icons
import CloseIcon from '@mui/icons-material/Close';
import GCASHLOGO from '../../assets/gcash.jpg'
import MAYALOGO from '../../assets/maya.png';


//Components
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ERRORS, CLEAR_ERRORS } from '../../redux/actions/types';
import { addCredits, getCashRequests } from '../../redux/actions/Users';
import { getUserData } from '../../redux/actions/Auth';
import moment from 'moment/moment';
import LinearWithValueLabel from '../TurnOverProgress';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
const dispatch = useDispatch();
  const { isWeb, pointsModal, errors, withdrawalModal, modal }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState('gcash');
  const [selected, setSelected] = useState(null);
  const [transList, setTransList] = useState([]);
  const [values, setValues] = useState({
    amount: null,
    commission: null,
    type: "deposit",
    note: '',
    fund: 'points',
    // phone: user.phone
  });

  const handlePayment = (val) => {
    setPayment(val)
    setValues({...values, paymentMethod: payment == 'gcash' ? 'Gcash' : 'Maya'})
}
  
  
const handleChanges = (prop) => event => {


  setValues({...values, [prop]: event.target.value})
  
}

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };


const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({type: CLEAR_ERRORS})
    if(!values.amount){
      alert('Enter amount!')
    }
    setLoading(true)
    
    dispatch(addCredits({...values, payment: payment, details: `Requested:${user.phone}(${user.username})-RECEIVER:${values.note}(${values.receiver})-AMOUNT:${values.amount}(${payment})`}))
    .then(() => {   
      setValues({
        amount: 0,
        type: "deposit",
        note: '',
        // phone: user.phone,
        commission: 0
      })
      dispatch({type: CLOSE_MODALS});
      dispatch(getUserData());
      setLoading(false)
    })
    .catch(err => {
    console.log(err, 'ERR')
      dispatch({type: SET_ERRORS, payload: err.response.data})    
      setLoading(false)

    })    
}

const handleTransactions = async () => {
  let transData = await dispatch(getCashRequests('withdrawal', moment(moment().format('YYYY-MM-DD'))));
   if(transData && transData.length !== 0){
     setTransList(transData)
   } else {
     setTransList([])
   }
   
   
 }



useEffect(() => {
     if(modal == 'cashoutRequest') {
      handleTransactions()
      setValues({...values, phone: user.phone, type: 'cashoutRequest'})
    }
    
    return () => {
      setSelected(null)
    }

}, [ modal]);

console.log(user, 'USER')

let isAllowed = (!user.validBets && !user.turnOver) ? false : (Number(user.validBets > user.turnOver ? user.turnOver : user.validBets) / Number(user.turnOver)) < 1;

console.log(((Number(user.validBets > user.turnOver ? user.turnOver : user.validBets) / Number(user.turnOver)) * 100) < 100, 'is allow', isAllowed, (isAllowed ? true : loading), ((Number(user.validBets > user.turnOver ? user.turnOver : user.validBets) / Number(user.turnOver))))
console.log()
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal == 'cashoutRequest'}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={!isWeb}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp; {pointsModal ? 'ADD CREDITS' : withdrawalModal ? 'WITHDRAW CREDITS' : 'CASHOUT' }
        </BootstrapDialogTitle>
        <DialogContent 
        dividers 
        className="dialog-content text-white p-3"
        >
        <form onSubmit={handleSubmit} >
        <Box className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Typography className='text-14 dialog-content-title mt-1'>POINTS :</Typography> 
        {user.role == 'agent' && <Typography className='text-14 dialog-content-title mt-1'>COMMISSION :</Typography> }
        {(user.validBets || user.turnOver) 
          ?  <Typography className='text-14 dialog-content-title mt-1'>TURNOVER :</Typography>  : ""
        }
        </Box>
        <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Typography className='text-14  text-bold mt-1 pl-2 text-yellow'>₱{Number(selected ? selected.points : user.points).toFixed(2)}</Typography>
        {user.role == 'agent' &&  <Typography className='text-14 text-bold mt-1 pl-2 text-yellow'>₱{Number(selected ? selected.load : user.load).toFixed(2)}</Typography>}
        {(user.validBets || user.turnOver) 
        ? 
          <LinearWithValueLabel validBets={Number(user.validBets > user.turnOver ? user.turnOver : user.validBets)} turnOver={user.turnOver} progress={(Number(user.validBets > user.turnOver ? user.turnOver : user.validBets) / Number(user.turnOver)) * 100}/>
        : ""
        }
        </Box>
        
        </Box>
        <br/>
  
      <div className="d-flex justify-content-center">
      <div className={`gcash-wrapper clickable ${payment == 'gcash' ? 'card-selected' : '' }`} onClick={() => handlePayment('gcash')}>
        <img src={ GCASHLOGO} width="64px" className='gcash-img '/>
      </div>
      <div className={`gcash-wrapper clickable ${payment == 'maya' ? 'card-selected' : '' }`} onClick={() => handlePayment('maya')}>
      <img src={MAYALOGO} width="64px" height="60px" className='gcash-img  '/>
    </div>
      </div>
      <div className={`bg-gcash-form gcash-form p-3`} >
     <FormControl component="fieldset">
     <FormLabel component="legend" className='text-light input-label mt-2'>Amount</FormLabel>
     <FormGroup aria-label="position" row>
         <TextField
             id="outlined-size-small"
             size="small"
             fullWidth
             placeholder="0"
             className='login-input'
             value={values.amount}
             type="number"
             onChange={handleChanges('amount')}
           />
     </FormGroup>  
     
      
      <>
      <FormLabel component="legend" className='text-light input-label mt-3'>Receiver Name</FormLabel>
      <FormGroup aria-label="position" row>
          <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
                className='login-input'
              value={values.receiver}
              onChange={handleChanges('receiver')}
            >
            
            </TextField>
      </FormGroup>
      <FormLabel component="legend" className='text-light input-label mt-3'>Receiver Number <span className='text-yellow text-12'>***Your {payment == 'gcash' ? 'GCash' : 'Maya'} Number</span></FormLabel>
      <FormGroup aria-label="position" row>
          <TextField
              id="outlined-size-small"
              size="small"
              placeholder="Ex: 09xxxxxxxxx"
              fullWidth
              className='login-input'
              value={values.note}
              onChange={handleChanges('note')}
            />
      </FormGroup>
    
      </>
     
  
  

   
      

     
      </FormControl>
      <div className="mt-5 "/ >
      {errors.message && <p className="text-center text-danger">{errors.message}</p>}
      
            <Button className='w-100 text-bold' variant='contained'  
            disabled={(loading || isAllowed)}
            type="submit"
            >SUBMIT P2P</Button>
          </div>
          </form>
          <hr/>
          <Typography className="text-center">Withdrawal History</Typography>
          <hr/>
          {transList.map(a => {
            return (
              <div className="transaction-list-item mb-3">
              <div className="transaction-list-item-text">
              <strong className='text-yellow text-1-rem'>₱{Number(a.amount).toFixed(2)}</strong>&nbsp;<Chip size='small' label={a.paymentMethod} color={a.paymentMethod ==  "Maya" ? "success" : "primary"} variant="contained" className="chip" />
              <br/>
              <small> Receiver: &nbsp;&nbsp;&nbsp;{a.receiver}&nbsp;({a.note})</small><br/>
              </div>
              <div className="transaction-list-action text-center">
              <small>  {moment(a.createdAt).format('lll')} </small><br/>
              <Chip size='small' label={String(a.status).toUpperCase()} color={a.status === 'valid' ? 'success' : a.status === 'invalid' ? 'error' : 'warning'} variant="contained" className="chip-valid" />
              </div>
              <hr className="text-dark"/>
            </div>
            )

          })}
        </DialogContent>
        
      </BootstrapDialog>
    </div>
  );
}
