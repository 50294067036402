import * as React from 'react';
import { useState, useEffect } from 'react';
import '../../App.css';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Chip, Divider, Box, DialogTitle, DialogContent, MenuItem, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

//Icons
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS } from '../../redux/actions/types';
import { getTransactions } from '../../redux/actions/Users';
import moment from 'moment';
import 'moment/locale/de';


const FILTER_BY_USER = [
  {label: 'All', value: 'all'},
  {label: 'Points Purchases', value: 'purchase'},
  {label: 'Withdrawal', value: 'withdrawal'},
]


const FILTER_BY_AGENT = [
  {label: 'All', value: 'all'},
  {label: 'Commissions', value: 'commission'},
  {label: 'Points Purchases', value: 'purchase'},
  {label: 'Points Transfers', value: 'transfer'},
  {label: 'Withdrawals', value: 'loadWithdrawal'},
]


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const { isWeb, transactionsModal }  = useSelector(({uiReducer}) => uiReducer);
  const { user }  = useSelector(({auth}) => auth);
  const [today, setToday] = useState(moment(moment().format('YYYY-MM-DD')));
  const [filterBy, setFilterBy] = useState('all');
  const [transList, setTransList] = useState([]);
  const dispatch = useDispatch();

  moment.locale('en-us');

  const handleTransactions = async (fltr, dt) => {
   let transData = await dispatch(getTransactions(fltr, dt));
    if(transData && transData.length !== 0){
    console.log(transData, 'trans')
      setTransList(transData)
    } else {
      setTransList([])
    }
    
    
  }


  const handleChanges = (e, type) => {
    setTransList([])

      let dt = today;
      let fltr = filterBy;
      
      if(type == 'date'){
        dt = e;
        setToday(e)
      } else {
        fltr = e;
        setFilterBy(e)
      }
      
      
      handleTransactions(fltr, dt)
  }
  

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  

  useEffect(() => {
    if(transactionsModal){
      handleTransactions(filterBy, today)
      }
      
      return () => {
        setToday(moment(moment().format('YYYY-MM-DD')))
        setFilterBy('all')
      }
  }, [transactionsModal])

let totalAmount = transList.reduce((n, {amount}) => n + amount, 0)

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={transactionsModal}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;TRANSACTIONS
        </BootstrapDialogTitle>
   
        <Box className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker 
          value={today}
          defaultValue={moment(new Date()).format('YYYY-MM-DD')}
          onChange={(newValue) => handleChanges(newValue, 'date')}
          style={{width: '45%', color: 'white'}}
        />
      </LocalizationProvider>
      <TextField
      id="outlined-size-small"
      select
      style={{width: '45%', color: 'white'}}
        color="primary"
      // className='login-input'
      defaultValue={filterBy}
      value={filterBy}
      onChange={(e) => handleChanges(e.target.value, 'filter')}
    >
    {user.role !== 'user' ? FILTER_BY_AGENT.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    )) : FILTER_BY_USER.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
    </TextField>
      </Box>
      <DialogContent dividers 
      className="dialog-content text-white m-h-300"
      >
    {filterBy != 'all' && <Box style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '20px',
      }}>
      <Typography>Total</Typography>
      <Typography className="text-yellow text-18 text-bold">₱{Number(totalAmount).toFixed(2)}</Typography>
      </Box>
    }
        <Divider className='text-white bg-white w-100 pofile-divider'/>
      <br/>
        <div className="transaction-list">
          {transList.map((a, index) => {
            return (
              <div key={index} className="transaction-list-item mb-3">
              <div className="transaction-list-item-text">
              <div style={{width: '150px'}}>
              {a.type === 'Purchased' ? 
                <Chip size='small' label={String(a.user) == String(user.userId) ? a.title : "Points Purchased"} color={String(a.user) == String(user.userId) ? "success" : "error"} variant="contained" className='w-150 chip' /> : a.type === 'Commission' ?  
                <Chip size='small' label={a.type} color="warning" variant="contained" className='w-150 chip' /> 
                : a.type === 'CashOut' ?  <Chip size='small' label={String(a.user) != String(user.userId) ? 'Cash-out' : 'Withdrawal'} color={String(a.user) != String(user.userId) ? 'primary' : 'info'} variant="contained" className='chip w-150' /> :
                <></>}
                </div>
       
              {a.type === 'Purchased'  ? <span><small>From: </small><strong className="text-primary"> {a.from.username}</strong>  <small>To:</small> <strong className="text-info">{a.to.username}</strong></span> : 
              a.type === 'CashOut' ?  <span><small>From: </small><strong className="text-primary"> {a.from.username}</strong>  <small>To:</small> <strong className="text-info">{a.to.username}</strong></span>
              
              :
              a.type === 'Commission' ? 
              <span><small style={{fontSize: '12px'}}>{a.title}</small> </span>
              : <></>
            }
              </div>
      
              <div className="transaction-list-action text-center">
              <strong className='text-yellow'>₱{Number(a.amount).toFixed(2)} </strong>
              <br/>
              <small className='text-lose'>{moment(a.createdAt).format('YYYY/MM/DD hh:mm a')}</small>
      
              </div>
              <hr className="text-dark"/>
            </div>
            )
          })}
      
         </div>
         </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
