import axios from 'axios';
import { CLEAR_EVENT, CLEAR_EVENT_FORM, CLEAR_MATCH, CLEAR_SEGMENT, SET_ACCOUNTS, SET_BET, SET_BETS, SET_BETS_HISTORY, SET_EVENT, SET_EVENTS, SET_FIGHT, SET_MATCH, SET_MATCHES, SET_RESULTS, SET_SEGMENT, SET_TIMELAPSE } from './types';


// import CrudService from '../../services/http-api/crud.service';
import commonData from '../../utils/commonData';
import { authHeader } from '../auth-header';
import { getBalance } from './Auth.js';
import { decryptData, encryptData } from '../../utils/encryption';


export const createEvent = (data) => dispatch => {
  return   axios
    .post(`${commonData.apiUrl}/events`, data)
    .then(({data}) => {
        dispatch(getEvents())
    })
    .catch(error => {
      console.log(error)
    });
};

export const updateEvent = (data, id) => dispatch => {
  return   axios
    .put(`${commonData.apiUrl}/events/${id}`, data)
    .then(({data}) => {
        dispatch(getEvents())
        dispatch({type: CLEAR_EVENT_FORM})
    })
    .catch(error => {
      console.log(error)
    });
};

export const getEvents = () => dispatch => {
    return   axios
      .get(`${commonData.apiUrl}/events`)
      .then(({data}) => {
          dispatch({type: SET_EVENTS, payload: data.d})
      })
      .catch(error => {
        console.log(error)
      });
};

export const getEvent = (id) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/events/${id}`)
    .then(({data}) => {
        dispatch({type: SET_EVENT, payload: data.d})
    })
    .catch(error => {
      console.log(error)
    });
};

export const setLiveEvent = (id) => dispatch => {
  return   axios
    .post(`${commonData.apiUrl}/events/live/${id}`)
    .then(({data}) => {
      dispatch(getEvents())
    })
    .catch(error => {
      console.log(error)
    });
};


export const getLiveEvent = (id) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/events/live`)
    .then(({data}) => {
        if(data.d){
          let { event, stream, betting} = data.d;
          if(stream){
            let {  fightStatus, fightNo, segmentId, timelapse, results } = stream;
            if(segmentId){
                  dispatch({type: SET_SEGMENT, payload: segmentId})
                  dispatch({type: SET_TIMELAPSE, payload: timelapse})
                  dispatch({type: SET_FIGHT, payload: {fightStatus: fightStatus, fightNo}})
                  if(betting){
                    dispatch({type: SET_BETS, payload: betting})
                  }
                }
                
                if(results){
                  dispatch({type: SET_RESULTS, payload: results})
                }
              }
          dispatch({type: SET_EVENT, payload: event})
        } else {
          dispatch({type: CLEAR_EVENT})
        }
    })
    .catch(error => {
      console.log(error)
    });
};




export const deleteEvent = (id) => dispatch => {
  return   axios
    .delete(`${commonData.apiUrl}/events/${id}`)
    .then(({data}) => {
          dispatch(getEvents());
    })
    .catch(error => {
      console.log(error)
    });
};

export const createMatch = (data, id) => dispatch => {
  return   axios
    .post(`${commonData.apiUrl}/matches/${id}`, data)
    .then(({data}) => {
          // dispatch(getEvent(id));
          dispatch({type: CLEAR_MATCH})
        // dispatch({type: SET_EVENTS, payload: data.d})
    })
    .catch(error => {
      console.log(error)
    });
};

export const createSegment = (data, id) => dispatch => {
  return axios.post(`${commonData.apiUrl}/events/segment/${id}`, data)
    .then(({data}) => {
          // dispatch(getEvent(id));
          dispatch(getEvents())
          dispatch({type: CLEAR_SEGMENT})
        // dispatch({type: SET_EVENTS, payload: data.d})
    })
    .catch(error => {
      console.log(error)
    });
};


export const getMatch = (id) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/matches/${id}`)
    .then(({data}) => {
        dispatch({type: SET_MATCH, payload: data.d})
    })
    .catch(error => {
      console.log(error)
    });
};

export const getSegment = (id) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/events/segment/${id}`)
    .then(({data}) => {
        dispatch({type: SET_SEGMENT, payload: data.d})
        return data.d;
    })
    .catch(error => {
      console.log(error)
    });
};

export const deleteSegment = (id) => dispatch => {
  return   axios
    .delete(`${commonData.apiUrl}/events/segment/${id}`)
    .then(({data}) => {
          dispatch(getEvents());
    })
    .catch(error => {
      console.log(error)
    });
};




export const deleteMatch = (id) => dispatch => {
  return   axios
    .delete(`${commonData.apiUrl}/matches/${id}`)
    .then(({data}) => {
          dispatch(getMatches());
    })
    .catch(error => {
      console.log(error)
    });
};


export const getMatches = () => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/matches`)
    .then(({data}) => {
        dispatch({type: SET_MATCHES, payload: data.d})
    })
    .catch(error => {
      console.log(error)
    });
};



export const betMatch = (data) => dispatch => {
console.log(data, 'betsing')
  return   axios
    .post(`${commonData.apiUrl}/betting/${data.side}`, { ...data, fight_number: data.fightNo}, { headers: authHeader() })
    .then(({data}) => {
        dispatch(getBalance())
        dispatch(getBets())
        return data
    })
};


export const getBet = (data) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/betting/current`, { headers: authHeader() })
    .then(({data}) => {
    console.log('BETS', data)
      dispatch({type: SET_BET, payload: decryptData(data.d)})
      return decryptData(data.d)
    })
    .catch(error => {
      console.log(error)
    });
};


export const getBets = (data) => dispatch => {
  let opt = data ? `?userId=${data}` : '';
  return   axios
    .get(`${commonData.apiUrl}/betting/bets${opt}`, { headers: authHeader() })
    .then(({data}) => {
      dispatch({type: SET_BETS_HISTORY, payload: decryptData(data.d)})
      return decryptData(data.d)
    })
    .catch(error => {
      console.log(error)
      return []
    });
};

export const getManagedAccounts = (data) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/admin/users/${data}`, { headers: authHeader() })
    .then(({data}) => {
      return data
    })
    .catch(error => {
      console.log(error)  
    });
};

export const getMembersAccounts = (data) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/admin/members`, { headers: authHeader() })
    .then(({data}) => {
      return data.d
    })
    .catch(error => {
      console.log(error)  
      return []
    });
};

export const postManagedAccounts = (type, id) => dispatch => {
  return   axios
    .post(`${commonData.apiUrl}/admin/users/${type}/${id}`, {}, { headers: authHeader() })
    .then(({data}) => {
      return data.d
    })
    .catch(error => {
      console.log(error)  
    });
};

export const banManagedAccounts = (id) => dispatch => {
  return   axios
    .post(`${commonData.apiUrl}/admin/user/ban/${id}`, {}, { headers: authHeader() })
    .then(({data}) => {
      return data.d
    })
    .catch(error => {
      console.log(error)  
    });
};


export const putManagedAccounts = (type, data) => dispatch => {
  return   axios
    .put(`${commonData.apiUrl}/admin/users/${type}/${data.userId}`, {d: encryptData({comRate: data.comRate})}, { headers: authHeader() })
    .then(({data}) => {
      return data.d
    })
    .catch(error => {
      console.log(error)  
    });
};



export const getMOP = (type, data) => dispatch => {
  return   axios
    .get(`${commonData.apiUrl}/mop`, { headers: authHeader() })
    .then(({data}) => {
      console.log(decryptData(data.d), 'MOPS')
      return decryptData(data.d)
    })
    .catch(error => {
      console.log(error)  
    });
};
