import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { AppBar,  Tabs, Tab, Dialog, Box, Autocomplete, DialogTitle, DialogContent, FormControlLabel, Checkbox, IconButton, Typography, FormControl, TextField, Button, FormGroup, MenuItem, FormLabel } from '@mui/material';
import { configs } from '../../utils/config';
import axios from 'axios';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTheme } from '@mui/material/styles';


//Icons
import CloseIcon from '@mui/icons-material/Close';
import GCASHLOGO from '../../assets/gcash.jpg'
import MAYALOGO from '../../assets/maya.png';
import { GCashAccounts } from '../../utils/dummyData';
import GcashCard from '../GcashCard';


//Components
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ERRORS, CLEAR_ERRORS } from '../../redux/actions/types';
import { addCredits, getAgentUsers } from '../../redux/actions/Users';
// import ReceiverInput from '../ReceiverInput';
import { getUserData } from '../../redux/actions/Auth';
import '../../App.css';
import { getMOP } from '../../redux/actions/Data';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
const dispatch = useDispatch();
const theme = useTheme();
  const { isWeb, pointsModal, errors, withdrawalModal, modal }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);
  const [payment, setPayment] = useState('gcash');
const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [comPoints, setComPoints] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(null);
  const [mop, setMop] = useState(GCashAccounts);
  const [dataOptions, setOptions] = useState([]);
  const [values, setValues] = useState({
    amount: null,
    commission: null,
    phone: user.phone,
    receiver: GCashAccounts[0].value,
    type: 'cashinRequest',
    note: '',
    fund: 'points',
    // phone: user.phone
  });

  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);



    if(file){
      axios.post(configs.file_url + `/upload/${configs.uploadId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${configs.file_token}`
        },
      }).then(res => {
        setValues({...values, fileUrl: `${configs.file_url}/download/${res.data._id}`})
        setFileName(`${configs.file_url}/download/${res.data._id}`)
      })
      .catch(err => {
        setError('Something went wrong!')
      })
    }

}


  const handleChange = (event, newValue) => {
  
    dispatch({type: CLEAR_ERRORS})

    setSelected(null)
      if(newValue){
        setValues({
          amount: null,
          commission: null,
          type: "deposit",
          note: '',
          fund: 'points',
          phone: ''
        })          
      } else {
        setValues({
          amount: null,
          commission: null,
          phone: user.phone,
          receiver: GCashAccounts[0].value,
          type: 'cashinRequest',
          note: '',
          fund: 'points'
          })    
      }

      setFileName(null)
      setPayment('gcash')
      setActiveTab(newValue);
    };
  
  
const handleChanges = (prop) => event => {


  setValues({...values, [prop]: event.target.value})
  
}

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
    dispatch({type: CLEAR_ERRORS})

  };


const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    dispatch({type: CLEAR_ERRORS})
    let phone = comPoints ? values.phone : !activeTab ? user.phone : String(values.label).split('|')[0];
    dispatch(addCredits({...values, phone, payment, details: `SENDER:${values.sender}(${user.username})-RECEIVER:${values.receiver}(${user.username})-AMOUNT:${values.amount}(${payment})`}))
    .then(() => {   
      setValues({
        amount: '',
        type: "deposit",
        note: '',
        // phone: user.phone,
        commission: ''
      })
      dispatch({type: CLOSE_MODALS});
      dispatch(getUserData());
      setLoading(false)
    })
    .catch(err => {
    console.log(err, 'ERR')
      dispatch({type: SET_ERRORS, payload: err.response.data})    
      setLoading(false)
    })    
}

const handleMOP = () => {

  dispatch(getMOP())
  .then(data => {
    setMop(data)
  })
  .catch(err => {
  setMop(GCashAccounts)  
  })

}

const handleComPoints = (e) => {
  if(!comPoints == true){
    setValues({...values, phone: 'Commission To Points', label: 'Commission To Points'})
  } else {
    
    setValues({...values, phone: '', label: ''})
  }
  setComPoints(!comPoints)
}


const handlePayment = (val) => {
    setPayment(val)
    let newMop = mop.filter(a => a.type == val);
    setValues({...values, receiver: newMop[0].number})
}

const handleOptions = async () => {
  let opt = await dispatch(getAgentUsers());
  let newOpt = [];
  
  
  
opt.forEach(a => {
   let newObj = {
     ...a,
     label: `${a.phone}|${a.username}`
   }
     newOpt.push(newObj)
  })
  
  console.log(dataOptions, 'optionss')
  setOptions(newOpt)
    
 }


useEffect(() => {
 handleOptions()
}, [])


useEffect(() => {
    handleMOP()


    if(pointsModal){
      setValues({...values,   
        amount: null,
        commission: null,
        phone: user.phone,
        receiver: mop[0].number,
        type: 'cashinRequest',
        note: '',
        fund: 'points'
        })
    }
    return () => {
      setSelected(null)
      setValues({
        amount: null,
        commission: null,
        phone: user.phone,
        receiver: '',
        type: 'cashinRequest',
        note: '',
        fund: 'points',
        // phone: user.phone
      })
      setActiveTab(0)
      setFileName(null)
      setPayment('gcash')
      setComPoints(false);
    }

}, [pointsModal]);


console.log(values)


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={pointsModal }
        maxWidth="sm"
        fullWidth={true}
        fullScreen={!isWeb}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp; {pointsModal ? 'ADD CREDITS' : withdrawalModal ? 'WITHDRAW CREDITS' : 'CASHOUT' }
        </BootstrapDialogTitle>
        <DialogContent 
        dividers 
        className="dialog-content text-white p-3"
        >
       {pointsModal && 
        <AppBar position="static">
        <Tabs
         value={activeTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Cash-In" {...a11yProps(0)} />
          <Tab label="Transfer Points" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
       }      

       
       <TabPanel value={activeTab} index={0} dir={theme.direction}>
       <form onSubmit={handleSubmit} >
        
        
       {!fileName ? 
        
       <>
        <div className="d-flex justify-content-center">
         <div className={`gcash-wrapper clickable ${payment == 'gcash' ? 'card-selected' : '' }`} onClick={() => handlePayment('gcash')}>
           <img src={ GCASHLOGO} width="64px" className='gcash-img '/>
         </div>
         <div className={`gcash-wrapper clickable ${payment == 'maya' ? 'card-selected' : '' }`} onClick={() => handlePayment('maya')}>
         <img src={MAYALOGO} width="64px" height="60px" className='gcash-img  '/>
       </div>
         </div>
         <br/>
         <GcashCard type={payment} mop={mop} />
         
         <br/>
         </>
         :
         <>
         <span className="clickable" onClick={() => {
           setFileName(null)
         }}>Back</span>
         <div>
         <img src={fileName} height="300px" width="100%" />
         </div>
         </>
         }
         <div className={`${payment == 'gcash' ? 'bg-gcash-form' : payment == 'maya' ? 'bg-maya-form' : ''} gcash-form p-3`}>
           {!fileName ? 
           <>
           <span className='text-white text-center'>Screenshot of the Receipt</span>
           <Button color="primary" aria-label="upload picture" component="label" className='w-100'>
             <input hidden accept="image/*" type="file" onChange={handleFileChange} />
             <div className='d-flex gcash-form-control'>
               <AttachFileIcon  className="text-white"/>
               <div className='gcash-form-input'><span className='text-15'>Choose Receipt</span></div>
               </div>
           </Button>
           </>
           :
           <>
              <Button color="primary" aria-label="upload picture" component="label"  className='text-bold w-100' >
             <input hidden accept="image/*" type="file" onChange={handleFileChange} />
             <AttachFileIcon  className="text-white"/>
               CHANGE IMAGE FILE
           
           </Button>
           <FormControl component="fieldset">
      <FormLabel component="legend" className='text-light input-label mt-2'>Amount</FormLabel>
       <FormGroup aria-label="position" row>
           <TextField
               id="outlined-size-small"
               size="small"
               fullWidth
               placeholder="Ex: 100"
               className='login-input'
               value={values.amount}
               type="number"
               onChange={handleChanges('amount')}
             />
       </FormGroup>
      <FormLabel component="legend" className='text-light input-label mt-3'>Sender <span className='text-yellow text-12'>***Your {payment == 'gcash'  ? 'GCash' : 'Maya' } Number</span></FormLabel>
       <FormGroup aria-label="position" row>
           <TextField
               id="outlined-size-small"
               size="small"
               placeholder="Ex: 09xxxxxxxxx"
               fullWidth
               className='login-input'
               value={values.sender}
               onChange={handleChanges('sender')}
             />
       </FormGroup>
       <FormLabel component="legend" className='text-light input-label mt-3'>Receiver </FormLabel>
       <FormGroup aria-label="position" row>
           <TextField
               id="outlined-size-small"
               size="small"
               fullWidth
               className='login-input'
               value={values.receiver}
               onChange={handleChanges('receiver')}
             />
       </FormGroup>
       <FormLabel component="legend" className='text-light input-label mt-3'>Ref # (Last 5 Digits)</FormLabel>
       <FormGroup aria-label="position" row>
           <TextField
               id="outlined-size-small"
               size="small"
               fullWidth
               className='login-input'
               placeholder="Ex: xxxxx"
               value={values.refNo}
               onChange={handleChanges('refNo')}
             />
       </FormGroup>
 
   </FormControl>
   </>
   
   }
           
           
           <Button className='w-100 mt-2 text-bold' variant='contained'  
           disabled={!values.amount || !values.sender || !values.refNo}
           type="submit"
           >SUBMIT P2P </Button>
         </div>
         </form>
         
       </TabPanel>
       <TabPanel value={activeTab} index={1} dir={theme.direction}>
       <Box className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
       <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
       <Typography className='text-14 dialog-content-title mt-3'>TOTAL POINTS</Typography>      
       <Typography className='text-13 text-bold mt-2 pl-2 text-yellow'>₱{Number(selected ? selected.points : user.points).toFixed(2)}</Typography>
       </Box>
       <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
       {user.role == 'agent' && <>
       
       <Typography className='text-14 dialog-content-title mt-3'>TOTAL COMMISSION</Typography>      
       <Typography className='text-13 text-bold mt-2 pl-2 text-yellow'>₱{Number(selected ? selected.load : user.load).toFixed(2)}</Typography>
       </>
     }
       
       </Box>
       </Box>
       <br/>
       <form onSubmit={handleSubmit} >
     <div className='gcash-form bg-gcash-form p-3' >
    <FormControl component="fieldset">
    <FormLabel component="legend" className='text-light input-label mt-2'> Send Points To: (Phone # or Username)</FormLabel>
    <FormGroup aria-label="position" row>
      {!comPoints ?   <Autocomplete
        id="free-solo-demo"
        freeSolo
        value={values.label}
        className='login-input'
       onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValues({
            ...values,
            label: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValues({...values,
            label: newValue.inputValue,
          });
        } else {
          setValues({
          ...values,
          label: newValue?.label
        });
        }
      }} 
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Select "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return String(option.label);
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.label} 
          </li>
        );
      }}
  
        disabled={comPoints}
        fullWidth
        options={dataOptions}
        renderInput={(params) =>
        <TextField {...params} label="Search User" 
        id="outlined-size-small"
        size="small"
        placeholder="Username / Phone #"
        />
        
        }
      />
      :
        <TextField
        id="outlined-size-small"
        size="small"
        fullWidth
        placeholder="Username / Phone #"
        className='login-input'
        disabled={comPoints}
        value={values.phone}
        onChange={handleChanges('phone')}
      />
      }
    </FormGroup>
    {user.role == 'agent' && 
    <FormGroup aria-label="position" row>
    <FormControlLabel control={<Checkbox checked={comPoints} onChange={(e) => handleComPoints(e)} />} label="Commission To Points" />

</FormGroup>
  }
    
    <FormGroup aria-label="position" row>
</FormGroup>  
      <FormLabel component="legend" className='text-light input-label mt-2'>Amount</FormLabel>
    <FormGroup aria-label="position" row>
        <TextField
            id="outlined-size-small"
            size="small"
            fullWidth
            placeholder="0"
            className='login-input'
            value={values.amount}
            type="number"
            onChange={handleChanges('amount')}
          />
    </FormGroup>  
     </FormControl>
     <div className="mt-5 "/ >
     {errors.message && <p className="text-center text-danger">{errors.message}</p>}
     
           <Button className='w-100 text-bold' variant='contained'  
           disabled={loading}
           type="submit"
           >SUBMIT P2P</Button>
         </div>
         </form>
   
       </TabPanel>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
