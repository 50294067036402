const host = 'https://bugtech.online';
const url = `${host}/api`;
const apiUrl = `${url}/v1`;
// const streamUrl = `${host}:8000/live`;
const streamUrl = `https://bugtech.online/live`;
const staticUrl = `${url}/static`;
const assetsUrl = `${url}/assets`;
const webUrl = `https://swc.sharewin.pro`;
//const webUrl = `${host}`;
const socketUrl = `${host}`;



export default {
  hostUrl: url,
  apiUrl,
  staticUrl,
  assetsUrl,
  webUrl,
  socketUrl,
  streamUrl
};
