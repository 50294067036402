import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import {MenuItem, AppBar, Tabs, Tab, Dialog, Chip, Divider, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ACCOUNTS } from '../../redux/actions/types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getBets, getMembersAccounts } from '../../redux/actions/Data';
import ClearIcon from '@mui/icons-material/Clear';
import MenuButton from '../MenuButton';
import moment from 'moment';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MembersList() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useSelector(({auth}) => auth);
  const { isWeb, modal }  = useSelector(({uiReducer}) => uiReducer);
    const [activeFilter, setActiveFilter] = useState('agents')
    const [listData, setListData] = useState([]);
    const [betsList, setBetsList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [rnd, setRnd] = useState(0);
    const [values, setValues] = useState({});  
    const [activeTab, setActiveTab] = useState(0);
    const [selected, setSelected] = useState(null);  
    const [searchString, setSearchString] = useState('');


  const handleClose = () => {
    dispatch({type: CLOSE_MODALS});
    handleClear()
  };
  

  const handleSelect = (data) => {
      setSelected(data);
      handleBets(data.userId)
  }


  const handleClear = (data) => {
    setSelected(null);
    setBetsList([])
}
  


  
  const handleTransactions = async (fltr, dt) => {
    let requestsData = await dispatch(getMembersAccounts());
    setListData(requestsData);
     
     
   }
   
   const handleBets = async (id) => {
    let requestsData = await dispatch(getBets(id));
    setBetsList(requestsData);
   }
   
   const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
   const handleChangeIndex = (index) => {
    setActiveTab(index);
  };
  



  
  // const handleGetAccounts = (type) => {
  //   dispatch(getManagedAccounts(type))
  //   .then(res => {      
  //       let {d, c} = res;
  //       dispatch({type: SET_ACCOUNTS, payload: d})
  //       setCounts(c)
  //   })
  //   .catch(err => {
  //       console.log('SOMETHING WENT WRONG!')
  //   })
  // }
  
  useEffect(() => {
    if(modal){
      handleTransactions(activeFilter);
    }
    
    return () => {
        dispatch({type: SET_ACCOUNTS, payload: []})
        setValues(null);
        setListData([]);
        setSearchString('');
        setFilteredData([]);
    }
  }, [modal, activeFilter])
  

  
  
  useEffect(() => {
    let filtered = [];
    if(searchString){
       filtered = listData.filter(a => String(a.username).toLowerCase().trim('').includes(String(searchString).toLowerCase().trim('')) || String(a.phone).toLowerCase().trim('').includes(String(searchString).toLowerCase().trim('')));
       filtered = filtered.filter(a => activeTab ? a.role == 'agent' : a.role == 'user') 
    } else {
      filtered = listData.filter(a => activeTab ? a.role == 'agent' : a.role == 'user');
    }
    filtered.sort(function (a, b) {
      if (a.username < b.username) {
        return -1;
      }
      if (a.username > b.username) {
        return 1;
      }
      return 0;
    });
      
      setFilteredData(filtered)
      setRnd(Math.random())
    }, [activeTab, searchString, listData]);

  
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={modal == 'members'}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;All Members
        </BootstrapDialogTitle>
    
        <div dividers 
        className="dialog-content text-white"
        style={{height: '100vh', overflow: 'scroll'}}
        >
          {selected ? 
            <Box className="p-2">
        <div className="clickable" onClick={() => {
          handleClear()
        }}><ArrowBackIosIcon fontSize="sm"/> <small>Back</small></div>
        <br/>
        <div className="d-flex justify-content-between">
        <Typography className="text-bold"  style={{marginLeft: '10px'}}>{selected.username}</Typography>
        <small className="text-warning" style={{marginRight: '10px'}}>Betting History</small>
        </div>
        <hr/>
        {betsList.map((a, index) => {
          return (
            <div key={index} className="transaction-list-item mb-3">
            <div className="transaction-list-item-text">
            <div className='w-100 d-flex align-items-center justify-content-start bets-card pr-1 mb-1'>
            <div className={`bet-number opacity-8 ${a.betSide}`}>
            <p className='body-2 mb-0'>  #{a.fightNo}</p> 
            </div>
            <div >
            <p className='body-2 mb-0 text-white'> {Number(a.amount).toFixed(2)}</p> 
            </div>
            <div className="flex-spacer"/>
            {a.status === "done" && 
            <div className='d-flex align-items-center justify-content-center'>
                    {a.isWin ?
                <>
              <div className='text-green'>WIN {Number(a.payout).toFixed(2)}</div>&nbsp;&nbsp;
              <small className='ml-2 text-white'>{Number(a.payoutRate * 100).toFixed(0)}%</small>
              </>
              :
              <div className='text-lose'>{a.winSide === "draw" ? "RETURNED - DRAW" : a.winSide === "cancelled" ? "CANCELLED" : "LOSE"}</div>
            }
            
            </div>
            
            
             }
            </div>
            <div className="w-100 d-flex justify-content-between pl-1 pr-1">
            <small>Running Balance: {Number(a.runningBalance).toFixed(2)}</small>
            <small>{moment(a.createdAt).fromNow()}</small>

            </div>
            </div>
            </div>)
          })}
        {betsList.length == 0 && <div className="m-5" style={{textAlign: 'center'}}><strong>Bets is Empty</strong></div>}  
        </Box>

      : 
      <>
      <Box className="p-2 bg-white" style={{display: 'flex', justifyContent: 'space-between'}}>
      <TextField
        fullWidth
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        className="text-white mb-1"
        label="Search"
        placeholder="Search by username / mobile #."
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end"><IconButton onClick={() => setSearchString('')}><ClearIcon className="text-white"/></IconButton></InputAdornment>,
        }}
      />
      </Box>
      
      <AppBar position="static">
      <Tabs
       value={activeTab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Players" {...a11yProps(0)} />
        <Tab label="Agents" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
    <SwipeableViews
    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    index={activeTab}
    onChangeIndex={handleChangeIndex}
  >
    <TabPanel value={activeTab} index={0} dir={theme.direction}>
    <div className="transaction-list">
    <Divider className='text-white bg-white w-100 pofile-divider mb-3'/>
    {filteredData.map((a, index) => {
      let newUplines = a.uplines.filter(ab => ab.userLevel > user.access)
      return (
      <>
        <div key={index} className="transaction-list-item">
        <div className="transaction-list-item-text flex-grow-1">
        <strong className='text-primary text-1-rem'>{a.username}</strong>&nbsp;&nbsp;{a.isActive && <span class="dot"></span>}
        <br/>   
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
        <div style={{flexGrow: 1, width: '25%'}}>
        <small> Points:</small>&nbsp;<small className="text-yellow">₱{Number(a.points).toFixed(2)}</small><br/>
        </div>
        <div style={{flexGrow: 1}}>
        </div>
        </div>
        </div>
        <div
        className="transaction-list-action text-center">
 
        {user.userId == a.referral && 
        <IconButton>
          <MenuButton data={a} onClose={() => handleClose()}/>
          </IconButton>
        }
        <IconButton
        onClick={() => handleSelect(a)}
      >
      <FactCheckIcon color="info"/>
      </IconButton>
        </div>
      </div>
      <div className="d-flex justify-content-between w-100">
      <div className="d-flex p-1">
      {newUplines.length == 0 ? 
        <small className="text-info">Direct</small>     
      : newUplines.map((ab, index) => {
      return (
        <small>{index ? "/" : ""} {ab.username}&nbsp;</small>
      )
      })}
      </div>
      <div className="d-flex p-1">
        {a.isNew && <strong className="text-success">NEW</strong>}
      </div>
      </div>
      <Divider className='text-white bg-white w-100 pofile-divider mb-3'/>
      </>
      )
    })}
    </div>
      
    </TabPanel>
    <TabPanel value={activeTab} index={1} dir={theme.direction}>
    <div className="transaction-list">
    <Divider className='text-white bg-white w-100 pofile-divider mb-3'/>
    {filteredData.map((a, index) => {
      let newUplines = a.uplines.filter(ab => ab.userLevel > user.access)
    
      return (
      <>
        <div key={index} className="transaction-list-item">
        <div className="transaction-list-item-text flex-grow-1">
        <strong className='text-primary text-1-rem'>{a.username}</strong>&nbsp;&nbsp;{a.isActive && <span class="dot"></span>}
        <br/>   
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
        <div style={{flexGrow: 1, width: '25%'}}>
        <small> Points:</small>&nbsp;<small className="text-yellow">₱{Number(a.points).toFixed(2)}</small><br/>
        { user.admin && <><small> Commission:</small>&nbsp;<small className="text-yellow">₱{Number(a.load).toFixed(2)}</small><br/></>
      }
        </div>
        <div style={{flexGrow: 1}}>
        </div>
        </div>
        </div>
        <div
        className="transaction-list-action text-center">
        <IconButton
          onClick={() => handleSelect(a)}
        >
        <FactCheckIcon color="info"/>
        </IconButton>
        <IconButton>
          <MenuButton data={a} onClose={() => handleClose()}/>
          </IconButton>
        </div>
      </div>
      <div className="d-flex justify-content-between w-100">
      <div className="d-flex p-1">
      {newUplines.length == 0 ? 
        <small className="text-info">Direct</small>     
      : newUplines.map((ab, index) => {
      return (
        <small>{index ? "/" : ""} {ab.username}&nbsp;</small>
      )
      })}
      </div>
      <div className="d-flex p-1">
        {a.isNew && <strong className="text-success">NEW</strong>}
      </div>
      </div>
      <Divider className='text-white bg-white w-100 pofile-divider mb-3'/>
      </>
      )
    })}
    </div>

    </TabPanel>
  </SwipeableViews>
    
      </>
         
        }
        </div>
      </BootstrapDialog>
    </div>
  );
}
