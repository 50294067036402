import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField, IconButton, Typography, InputAdornment } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

//Icons
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS } from '../../redux/actions/types';

import ImageViewer from 'react-simple-image-viewer';
import { COLORS } from '../../constants/Theme';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 1 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					size="small"
					sx={{
						position: 'absolute',
						right: 4,
						top: 4,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
	const { isWeb, promoModal } = useSelector(({ uiReducer }) => uiReducer);
	const dispatch = useDispatch();
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [imgUrl, setImgUrl] = useState([]);
	const images =
		[
			{
				id: 0,
				src: 'https://files.bugtech.online/api/v1/files/download/669923b44c18a3e4011edd93',
				title: 'Lucky Streak'
			},
			{
				id: 1,
				src: 'https://files.bugtech.online/api/v1/files/download/669923b44c18a3e4011edd8e',
				title: 'Sabong World Cup'
			},
		]

	const handleClose = () => {
		dispatch({ type: CLOSE_MODALS })
	};

	const openImageViewer = React.useCallback((data) => {
		console.log(data, "dDDDSS")
		setCurrentImage(data.id);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const handleImageString = () => {
		let stringArray = []
		images.map(data => {
			stringArray.push(data.src)
		})
		setImgUrl(stringArray)
		return
	}

	useEffect(() => {
		handleImageString()
	}, [])

	return (
		<div>
			<BootstrapDialog
				onClose={() => handleClose()}
				aria-labelledby="customized-dialog-title"
				open={promoModal}
				maxWidth="md"
				fullWidth={false}
				fullScreen={!isWeb ? true : false}
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
					&nbsp;&nbsp;PROMO
				</BootstrapDialogTitle>
				<DialogContent dividers
					className="dialog-content text-white"
					fullWidth={false}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-around',
						alignItems: 'center',
						height: '80vh'
					}}
				>
			
					{
						images.map((data, index) => (
							<img
								src={data.src}
								onClick={() => openImageViewer(data)}
								// onClick={ () => console.log(index, "INDEX HERE!")}
								/* width="100%" */
								// height="350"
								key={index}
								style={{ marginBottom: '2%', width: '100%', height: '370px' }}
							/>
						))
					}
					{isViewerOpen && (
						<ImageViewer
							src={imgUrl}
							currentIndex={currentImage}
							disableScroll={true}
							closeOnClickOutside={true}
							onClose={closeImageViewer}
							backgroundStyle={{ backgroundColor: COLORS.transparentBlack7 }}
						/>
					)}
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
}
