import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, CircularProgress, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment, Grid} from '@mui/material';



//Icons
import CloseIcon from '@mui/icons-material/Close';




import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_SNACKBAR, CLEAR_ERRORS, SET_ERRORS } from '../../redux/actions/types';
import { betAmounts } from '../../utils/dummyData';
import { betMatch, getBet } from '../../redux/actions/Data';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const { betModal }  = useSelector(({uiReducer}) => uiReducer);
  const { user }  = useSelector(({auth}) => auth);
  const dispatch = useDispatch();
    const [amount, setAmount] = useState('');
    const [selected, setSelected] = useState(0);
    const [error, setError] = useState(null);
    const [sure, setSure] = useState(false);
    const [loading, setLoading] = useState(false);
  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  
  const handleChanges = (val) => {
    setError(null)
  
    if(val > user.points){
    setError('Insuffecient Fund!');
    } else if(val < 20) {
      setError('Below Minimum')
    } else if(!Number(val)){
      setError('Not a number')
    }
    
    
    setAmount(val)
    setSelected(val);  
  }
  
    
  const handleAmount = (val) => {
    setAmount(val);
    setSelected(val);
    setError(null)

  }
  
  
  const handleSubmitBet = async (e) => {
    e.preventDefault();
    dispatch({type: CLEAR_ERRORS})
    if(!Number(amount)){
      return setError('Not a number')
    }
  
    
    if(amount >= (user.points + 1) ){
        return setError('Insuffecient Fund!');
      } 
      
      
   if(!sure) return setSure(true);
   setError(null)
    setLoading(true)
    
    if(user.points !== 0){
      dispatch(betMatch({side: betModal, amount}))
      .then(() => {
        // setTimeout(() => {
          dispatch({type: SET_SNACKBAR, payload: { message: 'Bet submitted successfully!', type: 'success', color: 'success' }})
          dispatch({type: CLOSE_MODALS})
          setLoading(false)
          setSure(false);
          dispatch(getBet())
        // }, 3000)
      })
      .catch(({response}) => {
        let { data } = response;
        // setTimeout(() => {
          dispatch({type: SET_SNACKBAR, payload: { message: data.m, type: 'error', color: 'danger' }})
          dispatch({type: SET_ERRORS, payload: data.errors})

          dispatch({type: CLOSE_MODALS})
          setLoading(false)
          setSure(false);
        // }, 5000)
      })
    } else {
      alert('Not enough points')
    }
  }
  
  
  
  
  let bets = betAmounts.filter(a => { return a <= user.points}).map((a, index) => {  
    return (
      <Grid item xl={4} sm={4} xs={4} lg={4} md={4} key={index} >
        <Button size="small" variant='contained' className={`${selected === a ? "selected" : ""}   ${betModal === "meron" ? "bg-meron" : betModal === "wala" ? "bg-wala" : "bg-draw"}`}  fullWidth onClick={() => handleAmount(a)}>{a}</Button>
      </Grid>
    )
  })
  
  useEffect(() => {
  if(user && user.points < 20){
    setAmount(user.points);
    setSelected(20)
  }
    return () => {
    setAmount(null)
    setSelected(0)
    setSure(false)
    }
  }, [betModal])
  
  return (
    <div>
      <BootstrapDialog
        // onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={betModal ? true : false}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className={` text-white text-15 ${betModal === "meron" ? "bg-meron" : betModal === "wala" ? "bg-wala" : "bg-draw"}`}>
        &nbsp;&nbsp;BET {betModal && betModal.toUpperCase()}
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmitBet}>
        <DialogContent dividers 
        className="text-white p-0"
        >
          {loading ? 
           <Box className="m-h-100 d-flex flex-column overflow-hidden align-items-center justify-content-center">
           <CircularProgress color="info" />
           <Typography variant='body1' className='text-primary'>Processing your bet.</Typography>
         </Box> 
         :  

        !sure ? 
        <>
        <div className="w-100 bg-white p-2 ">
          <TextField placeholder="0"  type="number" onChange={(e) => handleChanges(e.target.value)} fullWidth color='primary' size="small" value={amount} className="" helperText={`${error ? error :  `You only have ${Number(user.points - amount).toFixed(2)} credits.`}`} 
          error={error ? true : false}
          />
          </div>
          
          <Grid container spacing={1} className={`p-2 ${betModal === "meron" ? "bg-meron" : betModal === "wala" ? "bg-wala" : "bg-draw"}`}>
          <Grid item xl={4} sm={4} xs={4} lg={4} md={4} >
        <Button size="small" variant='contained' className={`${selected === user.points ? "selected" : ""} ${betModal === "meron" ? "bg-meron" : betModal === "wala" ? "bg-wala" : "bg-draw"}`} onClick={() => handleAmount(user.points)} fullWidth>ALL-IN</Button>
         </Grid>
      {bets}
          </Grid>
          </>
         :
        <div className="m-w-50 bg-white m-h-50 text-dark p-4">
            <div className='text-center'>
            Are you sure you want to bet<br/>
            <strong>{amount && Number(amount).toFixed(2)}</strong> 
            &nbsp;on&nbsp; 
            <strong>{betModal && betModal.toUpperCase()}?</strong>
            </div>
        </div>
        }
         </DialogContent>
         {!sure ?  
         
         <Button size="small" fullWidth type='submit'
        disabled={error || (!amount || amount === 0)}
        className={error || (!amount || amount === 0) ? "opacity-5" : ""}
         ><Typography className={`text-bold  ${betModal === "meron" ? "text-meron" : betModal === "wala" ? "text-wala" : "text-draw"}`}>SUBMIT BET</Typography></Button>
      :
         
         
         <div className='d-flex bg-white'>
         <Button size="small"
         disabled={loading}
         fullWidth onClick={() => setSure(false)}><Typography className={`text-bold text-lose  ${loading ? "opacity-5" : ""}`}>NO</Typography></Button>  
         <Button size="small"
        disabled={loading}
        //  onClick={() => handleClose()}
         fullWidth type="submit" className={`text-white border-0 
          ${loading ? "opacity-5" : ""}
         ${betModal === "meron" ? "bg-meron" : betModal === "wala" ? "bg-wala" : "bg-draw"}`}><Typography className={`text-bold  `}>YES</Typography></Button>
         </div>
    }
    </form>
      </BootstrapDialog>
    </div>
  );
}
