import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {MenuItem, Dialog, AppBar, Tabs, Tab, Button, Box, DialogTitle, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ACCOUNTS } from '../../redux/actions/types';
import { approveRequest, getCashPendingRequests, getCashDoneRequests } from '../../redux/actions/Users';
import { GCashAccounts } from '../../utils/dummyData';
import moment from 'moment';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CashRequestsList() {
  const dispatch = useDispatch();

  const { isWeb, modal }  = useSelector(({uiReducer}) => uiReducer);
  const [activeTab, setActiveTab] = useState(0);
    const [listData, setListData] = useState([]);  
    const [listDoneData, setListDoneData] = useState([]);  
    const [filteredData, setFilteredData] = useState([]);
    const [values, setValues] = useState(null);  

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  
  const handleChanges = (prop) => event => {
    setValues({...values, [prop]: event.target.value})
    
  }
  
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setValues(null)
  };
  
  const handleAction = (type) => {
    
      
    dispatch(approveRequest({...values, requestId: values._id, status: type}))
    .then(() => {
      handleTransactions()
      setValues(null)
      // dispatch({type: CLOSE_MODALS});
    
    })
    .catch(err => {
    console.log(err.response, 'err')
        // setError(err.response.data.message)
    })
    
    
  }

  const handleApprove = (data) => {
    
      
    dispatch(approveRequest({...data, requestId: data._id}))
    .then(() => {
      handleTransactions()
      setValues(null)
      // dispatch({type: CLOSE_MODALS});
    
    })
    .catch(err => {
    console.log(err.response, 'err')
        // setError(err.response.data.message)
    })
    
    
  }


  
  const handleTransactions = async (fltr) => {
    let requestsData = await dispatch(getCashPendingRequests());
    let requestsDoneData = await dispatch(getCashDoneRequests());
    setListData(requestsData);
    setListDoneData(requestsDoneData);
   }
  
  // const handleGetAccounts = (type) => {
  //   dispatch(getManagedAccounts(type))
  //   .then(res => {      
  //       let {d, c} = res;
  //       dispatch({type: SET_ACCOUNTS, payload: d})
  //       setCounts(c)
  //   })
  //   .catch(err => {
  //       console.log('SOMETHING WENT WRONG!')
  //   })
  // }
  
  useEffect(() => {
    if(modal){
      handleTransactions(activeTab);
    }
    
    return () => {
        dispatch({type: SET_ACCOUNTS, payload: []})
        setValues(null)
    }
  }, [modal])
  
  useEffect(() => {
    let ftype = activeTab == 0 ? 'deposit' : activeTab == 1 ? 'withdrawal' : 'invalid'; 
    
    let fltrd = listData.filter(a => { return ftype != 'invalid' ? a.type == ftype : a.status == ftype});
    
    if(activeTab == 2){
      setFilteredData(listDoneData)
    } else {
      setFilteredData(fltrd)
    }

  }, [listData, activeTab])
  
  
  
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={modal == 'cashRequestsList'}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;<strong>CASH REQUESTS</strong>
        </BootstrapDialogTitle>
    
        <div dividers 
        className="dialog-content text-white h-100"
        >

        <AppBar position="static">
        <Tabs
         value={activeTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Cash In" {...a11yProps(0)} />
          <Tab label="Cash Out" {...a11yProps(1)} />
          <Tab label="Done" {...a11yProps(2)} />

        </Tabs>
      </AppBar>
      <br/>
      
          {values ? 
        <>
        <div className='gcash-form bg-gcash-form p-3' >

        <span className="clickable" onClick={() => {
          setValues(null)
        }}>Back</span>
        { values.type === 'deposit' && <div>
        <img src={values.fileUrl} height="300px" width="100%" />
        </div>}
     <FormControl component="fieldset">
<FormLabel component="legend" className='text-light input-label mt-2'>Amount To {values.type == 'deposit' ? 'Load' : 'Withdraw'}</FormLabel>
 <FormGroup aria-label="position" row>
     <TextField
         id="outlined-size-small"
         size="small"
         fullWidth
         placeholder="Ex: 100"
         className='login-input'
         value={values.amount}
         type="number"
         onChange={handleChanges('amount')}
       />
 </FormGroup>
 {values.type == 'deposit' && 
 <>
<FormLabel component="legend" className='text-light input-label mt-3'>Sender Number <span className='text-yellow text-12'>***Your Gcash Number</span></FormLabel>
 <FormGroup aria-label="position" row>
     <TextField
         id="outlined-size-small"
         size="small"
         placeholder="Ex: 09xxxxxxxxx"
         fullWidth
         className='login-input'
         value={values.receiver}
       />
 </FormGroup>
 </>
}
{values.type === 'deposit' ? 
<>
<FormLabel component="legend" className='text-light input-label mt-3'>Receiver Number</FormLabel>
<FormGroup aria-label="position" row>
    <TextField
        id="outlined-size-small"
        size="small"
        fullWidth
        select
        className='login-input'
        value={values.sender}
      >
      {GCashAccounts.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.number} {option.name}
        </MenuItem>
      ))}
      </TextField>
</FormGroup>
<FormLabel component="legend" className='text-light input-label mt-3'>Ref # (Last 5 Digits)</FormLabel>
<FormGroup aria-label="position" row>
    <TextField
        id="outlined-size-small"
        size="small"
        fullWidth
        className='login-input'
        placeholder="Ex: xxxxx"
        value={values.refNo}
        onChange={handleChanges('refNo')}
      />
</FormGroup>
<FormLabel component="legend" className='text-light input-label mt-3'>Details</FormLabel>
<FormGroup aria-label="position" row>
    <TextField
        id="outlined-size-small"
        size="small"
        fullWidth
        className='login-input'
        value={values.details}
        placeholder="More Details"
        // onChange={handleChanges('details')}
      />
</FormGroup>
</>
:
<>
<FormLabel component="legend" className='text-light input-label mt-3'>Receiver Name</FormLabel>
 <FormGroup aria-label="position" row>
     <TextField
         id="outlined-size-small"
         size="small"
         placeholder="Ex: 09xxxxxxxxx"
         fullWidth
         className='login-input'
         value={values.receiver}
       />
 </FormGroup>
 <FormLabel component="legend" className='text-light input-label mt-3'>Receiver Number <span className='text-yellow text-12'>*** Gcash Number</span></FormLabel>
 <FormGroup aria-label="position" row>
     <TextField
         id="outlined-size-small"
         size="small"
         placeholder="Ex: 09xxxxxxxxx"
         fullWidth
         className='login-input'
         value={values.sender}
       />
 </FormGroup>
 <FormLabel component="legend" className='text-light input-label mt-3'>Details</FormLabel>
<FormGroup aria-label="position" row>
    <TextField
        id="outlined-size-small"
        size="small"
        multiline
        fullWidth
        className='login-input'
        value={values.details}
        placeholder="More Details"
        // onChange={handleChanges('details')}
      />
</FormGroup>
</>
}


</FormControl>
<br/>
{activeTab !== 2 ? 
     <div className="d-flex">
     <Button color="error" className=' m-2 w-100 mt-2 text-bold' variant='contained'  
onClick={() => values.type == 'deposit' ? handleAction('invalid') : handleApprove({...values, requestId: values.id, status: 'invalid'})}
>Invalid </Button>
<Button className='w-100 mt-2 m-2 text-bold' color="success" variant='contained'  
onClick={() =>  values.type == 'deposit' ? handleAction('valid') : handleApprove({...values, requestId: values.id, status: 'valid'})}
>Valid</Button>
</div> : 
<div>
<h5 className={`text-center text-bold ${values.status == "valid" ? "text-success" : "text-danger"}`} >{String(values.status).toUpperCase()}</h5>
</div>
}
</div>
        </>
      : 
        <div className="transaction-list h-100">
          {filteredData.map(a => {
            return (
              <div key={a.id} className="transaction-list-item mb-3">
              <div className="transaction-list-item-text">
              <strong className='text-primary text-1-rem'>{a.receiver}</strong>&nbsp;<small className='text-yellow text-1-rem'>₱{Number(a.requestedBy.points).toFixed(2)}</small>
              <br/>   
              <small>{moment(a.createdAt).fromNow()}</small>
              </div>
     
   
              <div
              className="transaction-list-action text-center">
                <IconButton
                onClick={() =>  setValues({...a, requestId: a.id})}
              >
      
              <FactCheckIcon color="info"/>
              </IconButton>
              </div>
              <hr className="text-dark"/>
            </div>
            )
          })}
         </div>
        }
        </div>
      </BootstrapDialog>
    </div>
  );
}
