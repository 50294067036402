import axios from 'axios';
import moment from 'moment';


// import CrudService from '../../services/http-api/crud.service';
import commonData from '../../utils/commonData';
import { authHeader } from '../auth-header';
import { CLEAR_BETS_HISTORY, CLEAR_TRANSACTION_DATA, CLOSE_MODALS, SET_ERRORS, SET_MODAL } from './types';
import { encryptData, decryptData } from '../../utils/encryption';




export const addCredits = (data) => dispatch => {
  return axios
  .post(`${commonData.apiUrl}/user/cash-request`, {data:encryptData(data)}, { headers: authHeader() })
  .then(doc => {
     return doc
  })
  .catch(error => {
    return Promise.reject(error);
  });
}

export const handlePoints = (data) => dispatch => {
  return axios
  .post(`${commonData.apiUrl}/admin/points/${data.phone}`, data, { headers: authHeader() })
  .then(doc => {
      dispatch({type: SET_MODAL, payload: null})
      dispatch({type: CLOSE_MODALS})
      dispatch({type: CLEAR_TRANSACTION_DATA})
     return doc
  })
  .catch(error => {
    return Promise.reject(error);
  });
}


export const approveRequest = (data) => dispatch => {
  return axios
  .put(`${commonData.apiUrl}/user/approve-request`, {data:encryptData(data)}, { headers: authHeader() })
  .then(doc => {
     return doc
  })
  .catch(error => {
    return Promise.reject(error);
  });
}


export const addUserPoints = (data) => dispatch => {
  return axios
  .post(`${commonData.apiUrl}/user/cash-request`, data, { headers: authHeader() })
  .then(doc => {
     return doc
  })
  .catch(error => {
    return Promise.reject(error);
  });
}

export const getCashRequests = (filter, today) => dispatch => {
  return axios
  .get(`${commonData.apiUrl}/user/cash-request?createdAt=${moment(today).format('YYYY-MM-DD')}&type=${filter}`, { headers: authHeader() })
  .then(({data}) => {
    // dispatch({type: SET_CASH_REQUESTS, payload: data.d})
     return decryptData(data.d)
  })
  .catch(error => {
    return Promise.reject(error);
  });
}



export const getCashPendingRequests = (filter, today) => dispatch => {
  return axios
  .get(`${commonData.apiUrl}/user/pending-request`, { headers: authHeader() })
  .then(({data}) => {
    // dispatch({type: SET_CASH_REQUESTS, payload: data.d})
     return decryptData(data.d)
  })
  .catch(error => {
    return Promise.reject(error);
  });
}

export const getCashDoneRequests = (filter, today) => dispatch => {
  return axios
  .get(`${commonData.apiUrl}/user/done-request?createdAt=${moment(today).format('YYYY-MM-DD')}&type=${filter}`, { headers: authHeader() })
  .then(({data}) => {
    // dispatch({type: SET_CASH_REQUESTS, payload: data.d})
     return decryptData(data.d)
  })
  .catch(error => {
    return Promise.reject(error);
  });
}


export const getTransactions = (filter, today) => dispatch => {
  return axios
  .get(`${commonData.apiUrl}/user/transactions?createdAt=${moment(today).format('YYYY-MM-DD')}&type=${filter}`, { headers: authHeader() })
  .then(({data}) => {
     return decryptData(data.d)
  })
  .catch(error => {
    return []
  });
}



export const clearBetsHistory = () => dispatch => {
  return axios
  .put(`${commonData.apiUrl}/betting/clear`, {},{ headers: authHeader() })
  .then(doc => {
    dispatch({type: CLEAR_BETS_HISTORY})
     return doc
  })
  .catch(error => {
    return Promise.reject(error);
  });
}

export const updateUsername = (data) => dispatch => {
  return axios
  .put(`${commonData.apiUrl}/auth/update/username`, {d: encryptData(data)},{ headers: authHeader() })
  .then(doc => {
     return doc
  })
  .catch(({response}) => {
    // dispatch()
    let data = response ? response.data : null;
    if(data && data.d){
      dispatch({type: SET_ERRORS, payload: data.d})
    } else {
      dispatch({type: SET_ERRORS, payload: {message: data.message}})
    }
  });
}


export const updatePassword = (data) => dispatch => {
  return axios
  .put(`${commonData.apiUrl}/auth/update/password`, {d: encryptData(data)},{ headers: authHeader() })
  .then(doc => {
     return doc
  })
  .catch(({response}) => {
    // dispatch()
    let data = response ? response.data : null;
    if(data && data.d){
      dispatch({type: SET_ERRORS, payload: data.d})
    } else {
      dispatch({type: SET_ERRORS, payload: {message: data.message}})
    }
  });
}



export const uploadFile = (data, callbackFun) => dispatch =>  {
 
    return axios
      .post(`${commonData.apiUrl}/upload`, data, { headers: {
        "Content-Type": "multipart/form-data"
      } })
      .then(doc => {
        let { data, message } = doc.data;
          return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
  
  
  
  export const getAgentUsers = (data) => dispatch => {
    return axios
    .get(`${commonData.apiUrl}/admin/players`, { headers: authHeader() })
    .then(({data}) => {
       return data.d
    })
    .catch(error => {
      return [];
    });
  }
